import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Plugins, PushNotification,PushNotificationToken, PushNotificationActionPerformed } from '@capacitor/core';
import { IonRouterOutlet, Platform, NavController } from '@ionic/angular';

const {Toast, PushNotifications, Modals}=Plugins;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    // for storing the returned subscription
    backButtonSubscription;
    @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  
    // set up hardware back button event.
    lastTimeBackPress = 0;
    timePeriodToExit = 2000;
  
    constructor(
      private platform: Platform,
      public router: Router,
      public navController: NavController
    ) {
      this.initializeApp();
    }
  
  
    initializeApp() {
      this.platform.ready().then(() => {
        this.backButtonEvent();        
      });
    }
  
    backButtonEvent() {
      this.backButtonSubscription = this.platform.backButton.subscribe(async () => {
        if (this.router.url=='/login' || this.router.url=='/signup' || this.router.url=='/admin/dashboard' || this.router.url=='/tabs/tab1' ) {
          if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
            navigator['app'].exitApp();
          } else {
            this.showToast('Press back again to exit App.');
            this.lastTimeBackPress = new Date().getTime();
          }
        }
        else
        {
          this.navController.back();
        }
      });
    }
  
    async showToast(msg) {
      await Toast.show({
        text: msg
      });
    }
  
    //Called when view is left
    ngOnDestroy() {
      // Unregister the custom back button action for this page
      this.backButtonSubscription.unsubscribe();
    }

    ngOnInit() {
      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',(notification: PushNotification) => {
          var audio1 = new Audio('assets/notification.mp3');
          audio1.play();
          let alertRet = Modals.alert({
            title: notification.title,
            message: notification.body
          }).then(()=>{
            window.location.reload();
          });
        }
      );
  
      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',(notification: PushNotificationActionPerformed) => {
          //alert('Push action performed: ' + JSON.stringify(notification));
          console.log('Push action performed: ' + notification);
          window.location.reload();
        }
      );
    }
  }
