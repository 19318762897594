import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./index/index.module').then( m => m.IndexPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule)
  },
  {
    path: 'manageloan',
    loadChildren: () => import('./pages/admin/loanmanagement/manageloan/manageloan.module').then( m => m.ManageloanPageModule)
  },
  {
    path: 'approvedloan',
    loadChildren: () => import('./pages/admin/loanmanagement/approvedloan/approvedloan.module').then( m => m.ApprovedloanPageModule)
  },
  {
    path: 'pendingloan',
    loadChildren: () => import('./pages/admin/loanmanagement/pendingloan/pendingloan.module').then( m => m.PendingloanPageModule)
  },
  
  {
    path: 'approveloan',
    loadChildren: () => import('./pages/admin/loanmanagement/approveloan/approveloan.module').then( m => m.ApproveloanPageModule)
  },
  {
    path: 'viewloandetails',
    loadChildren: () => import('./pages/admin/loanmanagement/viewloandetails/viewloandetails.module').then( m => m.ViewloandetailsPageModule)
  },
  {
    path: 'viewuserdetails',
    loadChildren: () => import('./pages/admin/loanmanagement/viewuserdetails/viewuserdetails.module').then( m => m.ViewuserdetailsPageModule)
  },
  {
    path: 'banksettings',
    loadChildren: () => import('./pages/admin/settingsmanagement/banksettings/banksettings.module').then( m => m.BanksettingsPageModule)
  },
  {
    path: 'uploaduser',
    loadChildren: () => import('./pages/admin/usermanagement/uploaduser/uploaduser.module').then( m => m.UploaduserPageModule)
  },
  {
    path: 'change-user-password',
    loadChildren: () => import('./pages/admin/usermanagement/change-user-password/change-user-password.module').then( m => m.ChangeUserPasswordPageModule)
  },
  {
    path: 'autoapprovedloan',
    loadChildren: () => import('./pages/admin/loanmanagement/autoapprovedloan/autoapprovedloan.module').then( m => m.AutoapprovedloanPageModule)
  },
  {
    path: 'scheduleview',
    loadChildren: () => import('./pages/admin/loanmanagement/scheduleview/scheduleview.module').then( m => m.ScheduleviewPageModule)
  },
  {
    path: 'viewpendingbyschedule',
    loadChildren: () => import('./pages/admin/loanmanagement/viewpendingbyschedule/viewpendingbyschedule.module').then( m => m.ViewpendingbyschedulePageModule)
  },
  {
    path: 'paymentgateway',
    loadChildren: () => import('./pages/admin/settingsmanagement/paymentgateway/paymentgateway.module').then( m => m.PaymentgatewayPageModule)
  },
  {
    path: 'paymentmethod',
    loadChildren: () => import('./pages/paymentmethod/paymentmethod.module').then( m => m.PaymentmethodPageModule)
  },
  {
    path: 'shortloanapprove',
    loadChildren: () => import('./pages/admin/loanmanagement/shortloanapprove/shortloanapprove.module').then( m => m.ShortloanapprovePageModule)
  },
  {
    path: 'disclaimer',
    loadChildren: () => import('./pages/disclaimer/disclaimer.module').then( m => m.DisclaimerPageModule)
  },
  {
    path: 'info_list',
    loadChildren: () => import('./pages/information/info-list/info-list.module').then( m => m.InfoListPageModule)
  },
  {
    path: 'aadharverf',
    loadChildren: () => import('./pages/aadharverf/aadharverf.module').then( m => m.AadharverfPageModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./pages/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'settingsmanagement',
    loadChildren: () => import('./pages/admin/settingsmanagement/settingsmanagement.module').then( m => m.SettingsmanagementPageModule)
  },
  {
    path: 'payoutlist',
    loadChildren: () => import('./pages/admin/loanmanagement/payoutlist/payoutlist.module').then( m => m.PayoutlistPageModule)
  },
  {
    path: 'autoapprovedata',
    loadChildren: () => import('./pages/admin/loanmanagement/autoapprovedata/autoapprovedata.module').then( m => m.AutoapprovedataPageModule)
  },
  {
    path: 'approvedata',
    loadChildren: () => import('./pages/admin/loanmanagement/approvedata/approvedata.module').then( m => m.ApprovedataPageModule)
  },
  {
    path: 'ftuser',
    loadChildren: () => import('./pages/admin/usermanagement/ftuser/ftuser.module').then( m => m.FtuserPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
